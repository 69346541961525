<script setup lang="ts">
export interface IconChevronRightProps {
  width?: number;
  height?: number;
  fill?: string;
}

const props = withDefaults(defineProps<IconChevronRightProps>(), {
  width: 16,
  height: 16,
  fill: "currentColor",
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.244 20 5 17.897l8.104-7.875L5 2.148 7.198 0 17.5 10.022z" :fill="props.fill" fill-rule="evenodd" />
  </svg>
</template>
